import React from "react";
import styled from "styled-components";
import { Container, Row, Col, Nav, Tab } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text, Box, Span } from "../components/Core";

const NavStyled = styled(Nav)`
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.secondary} !important;
  background-color: ${({ theme }) => theme.colors.light} !important;
  padding-top: 15px;
  padding-bottom: 15px;
  a {
    color: ${({ theme }) => theme.colors.dark} !important;
    &:hover,
    &:active,
    &:visited {
      color: ${({ theme }) => theme.colors.secondary} !important;
    }
  }
`;

const Faq = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Section className="pb-0">
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="9">
                <Title variant="hero">Frequently Asked Question</Title>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section>
          <Container>
            <Tab.Container id="left-tabs-example" defaultActiveKey="general">
              <Row>
                <Col md="4" className="mb-5 mb-md-0">
                  <NavStyled className="flex-column mr-md-5">
                    <Nav.Link eventKey="general">General</Nav.Link>
                    <Nav.Link eventKey="farmers">Farmers</Nav.Link>
                    <Nav.Link eventKey="florists">Florists</Nav.Link>
                  </NavStyled>
                </Col>
                <Col md="8">
                  <Tab.Content>
                    <Tab.Pane eventKey="general">
                      <Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                            How do I get Petaler?
                          </Title>
                          <Text variant="small">
                            Petaler launches March 15. Get Petaler from the
                            Apple App Store or the Google Play Store.
                          </Text>
                        </Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                            Why is it an app?
                          </Title>
                          <Text variant="small">
                            We built Petaler to be where you are. Which
                            generally, is outside and on-the-go. Also, we were
                            already drowning in emails, and wanted a better way
                            to break through the noise. Push noticiations have
                            an opening rate of 90%, and their click rate is 7
                            times higher than that of email marketing. No more
                            getting lost in the shuffle. All that being said,
                            our web platform is coming soon.
                          </Text>
                        </Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                            Will you provide support?
                          </Title>
                          <Text variant="small">
                            Yes. We are here to help you succeed. There is
                            support chat directly in the app, and you can always
                            reach us at support@getpetaler.com. We love hearing
                            from you.
                          </Text>
                        </Box>
                      </Box>
                    </Tab.Pane>
                    <Tab.Pane eventKey="farmers">
                      <Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                            How does it work?
                          </Title>
                          <Text variant="small">
                            Petaler is a mobile app that can be downloaded on
                            the App Store or Google Play Store starting March
                            15. When you sign up, we will ak you for information
                            about your farm. You account will be in review for
                            24-48 hours while we check everything out. We do
                            this to ensure the quality of growers on our
                            platform! While we are reviewing your account, you
                            can get set up - listing your inventory, pricing,
                            and schedule. Once you are approved, you farm is
                            made public on the platform.
                          </Text>
                        </Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                            What does it cost?
                          </Title>
                          <Text variant="small">
                            We are waiving the subscription fee for the 2021
                            season! There is a 4.5% transaction fee that covers
                            payment processing fees as well as the cost to keep
                            the lights on at Petaler. We know you work hard
                            everyday for your business, so we work hard to keep
                            our costs as low as possible for you.
                          </Text>
                        </Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                            Do I have control over....?
                          </Title>
                          <Text variant="small">
                            Yes. Atleast, probably yes. We built Petaler to
                            enable you to run your business how you see fit.
                            Only want to sell to your existing relationships?
                            Great. Want to set different prices for preferred
                            buyers? Go for it. Dictate where you deliver, and
                            when, and how much it costs? Set a cancellation
                            policy? Minimum lead time? Yes, yes, yes.
                          </Text>
                        </Box>
                        <Box mb={4}>
                          Didn’t find your answer? Ping us in the little chat
                          bubble in the bottom right of the screen, or shoot an
                          email to support@getpetaler.com.
                        </Box>
                      </Box>
                    </Tab.Pane>
                    <Tab.Pane eventKey="florists">
                      <Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                            Am I qualified to join?
                          </Title>
                          <Text variant="small">
                            Petaler is open to commercial florists and
                            designers. When you are onboarding we will ask for
                            information about your business and for your resale
                            certificate. Your account will be in review for
                            24-48 hours while you can explore around the app and
                            get set up. During that time, you will hear from us
                            either approving your account or requesting more
                            information.
                          </Text>
                        </Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                            What does it cost?
                          </Title>
                          <Text variant="small">
                            Petaler is available for no charge for florists and
                            designers. We make it safe, secure, and simple to
                            pay from your bank account using Plaid, which is
                            used by other financial apps such as Venmo and
                            Stripe. We understand the importance of keeping your
                            financial information safe, and Petaler never
                            receives or stores your banking credentials. If you
                            choose to pay with a credit card, there is a 2.2%
                            transaction fee.
                          </Text>
                        </Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                            How does it work?
                          </Title>
                          <Text variant="small">
                            In Petaler, you can browse availability from farmers
                            in your area. Farmers list their pickup and delivery
                            schedules, and you can place an order for
                            fulfillment depending on their schedule. Upon
                            fulfillment, payment is processed for your order.
                          </Text>
                        </Box>
                        <Box mb={4}>
                          Didn’t find your answer? Ping us in the little chat
                          bubble in the bottom right of the screen, or shoot an
                          email to support@getpetaler.com.
                        </Box>
                      </Box>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default Faq;
